<template>
  <section class="panel-padding">
    <div class="inner">
      <div class="blog-article-tags-container">
        <h2 class="heading">
          {{ componentData.fields.largeBlogArticlesListHeading }}
        </h2>

        <button
          v-if="!componentData.fields.hideTagFIlters"
          class="btn btn--keyline blog-article-filter"
          @click="toggleFilterBlock()"
        >
          Filter your results <span>{{ articleFilterSign }}</span>
        </button>

        <div
          v-if="!componentData.fields.hideTagFIlters"
          class="blog-article-tags"
          :class="{
            'blog-article-tags--active': activeFilterBlock,
          }"
        >
          <button
            v-for="tag in blogTags"
            :key="tag.id"
            class="btn btn--secondary btn--small btn--tag"
            :class="{ 'active-tag': tag.blogTagName === selectedTag }"
            @click="toggleFilter(tag.blogTagName)"
          >
            {{ tag.blogTagName }}
          </button>
        </div>
      </div>

      <div
        v-if="articles != null"
        :class="`blog-articles ${getBlogArticleBlockClass(index)}`"
      >
        <div
          v-for="(article, index) in paginatedBlogArticles[currentPage]"
          :key="index"
          class="blog-article"
        >
          <a :href="article.articleLink" class="blog-article__link">
            <div
              :class="`blog-article__image-wrapper blog-article__image-wrapper--${index}`"
            >
              <div
                :class="`blog-article__image blog-article__image--${index}`"
                :style="{ backgroundImage: `url(${article.headerImage})` }"
              ></div>
            </div>
            <div :class="`blog-article__body blog-article__body--${index}`">
              <div class="blog-article__header">
                <div class="blog-article__date">
                  <div class="icon">
                    <!-- <SvgIcon name="calendar" /> -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="{1.5}"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                      />
                    </svg>
                  </div>
                  <span>{{
                    publicSiteSettings.fields.globalLocale != "en-US"
                      ? formatDate(article.newsDate)
                      : convertToAmericanDate(article.newsDate.split("T")[0])
                  }}</span>
                </div>
                <h3 class="blog-article__title">
                  {{ article.headerTitle }}
                </h3>
              </div>
              <p>{{ article.blurb }}</p>
              <div class="blog-article__more">
                <span>Read the full article</span>
                <div class="icon">
                  <SvgIcon name="arrow" />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div v-if="articles != null" class="live-flights__pagination">
      <div class="pagination pagination--dynamic">
        <ul>
          <li class="pagination__prev">
            <a
              href=""
              class="btn btn--primary"
              @click.prevent="prevPage"
              :class="{
                disabled: currentPage === 0,
              }"
              >Prev</a
            >
          </li>
          <div>
            <li v-for="page in pages" :key="page">
              <a
                href=""
                :class="{ pagination__current: currentPage === page }"
                @click.prevent="goToPage(page)"
              >
                {{ page + 1 }}
              </a>
            </li>
          </div>

          <li class="pagination__next">
            <a
              href=""
              class="btn btn--primary"
              @click.prevent="nextPage"
              :class="{
                disabled: currentPage === paginatedBlogArticles.length - 1,
              }"
              >Next</a
            >
          </li>
        </ul>
      </div>
    </div>
    <h2 v-if="articles == null">
      Sorry, there are currently no blog articles available
    </h2>
  </section>
</template>

<script>
import axios from "axios";
import SvgIcon from "@/components/Icon";

export default {
  name: "LargeArticlesList",
  components: {
    SvgIcon,
  },
  props: ["selectedLanguage", "componentData", "publicSiteSettings"],
  data() {
    return {
      articles: [],
      webpage: "",
      blogTags: [],
      activeFilterBlock: false,
      articleFilterSign: "+",
      selectedTag:
        this.componentData.fields.startingFilterTag?.system.name != null
          ? this.componentData.fields.startingFilterTag?.system.name
          : null,
      pageSize: this.componentData.fields.articleListSize || 5,
      currentPage: 0,
      contentNodePickerUrl:
        this.componentData.fields.contentNodePicker?.system.urlSegment,
    };
  },
  mounted() {
    this.loadArticles();
  },
  watch: {
    selectedLanguage: "reloadPage",
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    getBlogArticleBlockClass(index) {
      const cycleLength = 5;
      const cycle = index % cycleLength;
      return "blog-article-block-container-type" + (cycle + 1);
    },
    goToPage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.paginatedBlogArticles.length - 1) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
    loadArticles() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

      const url =
        this.contentNodePickerUrl != null
          ? "/" + this.contentNodePickerUrl + "/"
          : this.webpage;

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/BlogPostsApi/GetAllArticles?url=${url}&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.articles = response.data.articles;
          this.articles =
            Object.keys(response.data.articles).length === 0 ||
            this.articles.length < 1
              ? null
              : this.articles;
          console.log(this.publicSiteSettings.fields.globalLocale);
          this.blogTags = response.data.blogTags;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filteredBlogArticles() {
      const cycleLength = 5;
      let _articleBlocks = [];
      let _dest = this.articles;
      if (this.selectedTag) {
        _dest = this.articles.filter((article) => {
          return article.categories.includes(this.selectedTag);
        });
      } else {
        _dest = this.articles;
      }

      //Create blocks of 5 from the resulting array for display purposes.
      for (let i = 0; i < _dest.length; i += cycleLength) {
        _articleBlocks.push(_dest.slice(i, i + cycleLength));
      }

      return _articleBlocks;
    },
    toggleFilterBlock() {
      this.activeFilterBlock = !this.activeFilterBlock;
      if (this.activeFilterBlock) {
        this.articleFilterSign = "+";
      } else {
        this.articleFilterSign = "-";
      }
    },
    toggleFilter(tag = null) {
      if (this.selectedTag === tag) {
        this.selectedTag = null;
      } else {
        this.selectedTag = tag;
      }
    },
    formatDate(date) {
      let newDate = new Date(date);

      let day = String(newDate.getDate()).padStart(2, "0");
      let month = String(newDate.getMonth() + 1).padStart(2, "0");
      let year = newDate.getFullYear();

      return `${day}/${month}/${year}`;
    },
    convertToAmericanDate(dateStr) {
      let [year, month, day] = dateStr.split("-");
      return `${month}/${day}/${year}`;
    },
  },
  computed: {
    paginatedBlogArticles: function () {
      let data = this.filteredBlogArticles().flat();
      return data.reduce((pages, item, index) => {
        const page = Math.floor(index / this.pageSize);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
        return pages;
      }, []);
    },
    pages: function () {
      const pageNumbers = [];
      for (let i = 0; i <= this.paginatedBlogArticles.length - 1; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    },
  },
};
</script>

<style lang="scss" scoped>
.blog-article-tags-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 30px auto;

  .heading {
    text-align: center;
  }
}
.btn--tag {
  background: white !important;
  color: $btn-primary !important;

  &:hover {
    background: $btn-primary !important;
    color: #fff !important;
  }

  &:focus {
    background: $btn-primary !important;
    color: #fff !important;
  }
  &.active-tag {
    background: $btn-primary !important;
  }
}
.blog-article-filter {
  width: 80%;

  @include breakpoint($tablet) {
    display: none;
  }
}
.blog-article-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  display: none;

  &--active {
    display: flex;
  }

  @include breakpoint($tablet) {
    display: flex;
  }
}

.blog-articles {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include breakpoint($tablet) {
    gap: 4.5rem;
  }
}

.blog-article {
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid #f2f2f2;
  transition: box-shadow 0.3s;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }

  &__link {
    display: flex;
    flex-direction: column;
    text-decoration: none;

    @include breakpoint($tablet) {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: 1fr;
    }

    &:hover {
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
        0 2px 4px -2px rgb(0 0 0 / 0.1);

      .blog-article__image {
        transform: scale(1.1);
      }
      .blog-article__more {
        .icon {
          transform: translateX(0.5rem);
        }
      }
    }
  }

  &__image-wrapper {
    grid-area: 1 / 1 / 2 / 4;
    overflow: hidden;

    &--0 {
      grid-area: 1 / 1 / 2 / 5;
    }
  }

  &__image {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    height: 30rem;
    transition: transform 0.8s;

    &--0 {
      @include breakpoint($tablet) {
        height: 40rem;
      }
    }
  }

  &__body {
    grid-area: 1 / 4 / 2 / 8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4rem;
    padding: 2rem;

    @include breakpoint($tablet) {
      padding: 3rem;
    }

    &--0 {
      grid-area: 1 / 5 / 2 / 8;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__date {
    display: flex;
    align-items: center;

    span {
      font-size: 1.25rem;
      font-weight: 400;
      color: #afafaf;

      @include breakpoint($tablet) {
        font-size: 1.5rem;
      }
    }

    .icon {
      width: 1.75rem;
      height: 1.75rem;
      color: #afafaf;

      @include breakpoint($tablet) {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &__more {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    span {
      font-size: 1.25rem;

      @include breakpoint($tablet) {
        font-size: 2rem;
      }
    }

    .icon {
      transition: transform 0.3s;
    }
  }
}

.pagination {
  text-align: center;
  display: none;
  margin-top: 3rem;

  @include breakpoint($tablet-sml) {
    display: block;
  }

  li {
    display: inline-block;

    a:not(.btn) {
      display: block;
      font-size: 1.8rem;
      color: $live-flights-btns-color;
      margin: 0 0.5rem;
      padding: 1rem;
      cursor: pointer;

      &.pagination__current,
      &:hover,
      &:active {
        color: #213368;
        text-decoration: none;
      }
    }

    &.unclickable a {
      cursor: default;
      color: #00a8e1;

      &.pagination__current,
      &:hover,
      &:active {
        cursor: default;
        color: #00a8e1;
      }
    }
  }

  &--dynamic {
    display: block;

    @media (max-width: 899px) {
      li {
        display: none;

        &.pagination__prev,
        &.pagination__next {
          display: inline-block;

          &.disabled {
            display: none;
          }
        }

        a {
          margin: 0;
        }
      }
    }

    .pagination__prev {
      float: left;
    }

    .pagination__next {
      float: right;
    }

    .disabled {
      visibility: hidden;
    }
  }
}
</style>
