<template>
  <section>
    <div id="StickyBookingBarDistribusionAirportTransfersIFrame">
      <div id="distribusion-search"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "StickyBookingBarDistribusionAirportTransfersIFrame",
  props: [
    "distribusionAirportTransfersIFramePinnedBookingBarSettings",
    "selectedLanguage",
  ],
  data() {
    return {
      currency:
        this.distribusionAirportTransfersIFramePinnedBookingBarSettings.fields
          .globalLocale === "en-EU"
          ? "EUR"
          : this.distribusionAirportTransfersIFramePinnedBookingBarSettings
              .fields.globalLocale === "en-GB"
          ? "GBP"
          : "USD",
      locale: this.selectedLanguage === "en-us" ? "en" : this.selectedLanguage,
    };
  },
  mounted() {
    this.initializeDistribusion();
  },
  watch: {
    selectedLanguage: "reloadPage",
  },
  methods: {
    async initializeDistribusion() {
      try {
        await this.loadStylesheet();
        await this.loadScript();
        await this.initializeWidget();
      } catch (error) {
        console.error("Failed to initialize Distribusion:", error);
      }
    },
    loadStylesheet() {
      return new Promise((resolve) => {
        const link = document.createElement("link");
        link.id = "distribusion-widget-link";
        link.href = "https://book.distribusion.com/sdk.1.0.0.css";
        link.rel = "stylesheet";
        link.onload = resolve;
        document.head.appendChild(link);
      });
    },
    loadScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.async = true;
        script.id = "distribusion-widget-script";
        script.src = "https://book.distribusion.com/sdk.1.0.0.js";
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    initializeWidget() {
      return new Promise((resolve, reject) => {
        const maxAttempts = 10;
        let attempts = 0;

        const tryInitialize = () => {
          if (typeof window.Distribusion !== "undefined") {
            const root = document.getElementById("distribusion-search");
            if (!root) {
              return;
            }

            window.Distribusion.Search.mount({
              root,
              partnerNumber: parseInt(
                this.distribusionAirportTransfersIFramePinnedBookingBarSettings
                  .fields.distribusionWidgetPartnerNumber
              ),
              locale: this.locale,
              currency: this.currency,
              defaults: {
                departureStation:
                  this
                    .distribusionAirportTransfersIFramePinnedBookingBarSettings
                    .fields.distribusionWidgetDepartureStation,
                departureArea:
                  this
                    .distribusionAirportTransfersIFramePinnedBookingBarSettings
                    .fields.distribusionWidgetDepartureArea,
                departureCity:
                  this
                    .distribusionAirportTransfersIFramePinnedBookingBarSettings
                    .fields.distribusionWidgetDepartureCity,
                arrivalStation:
                  this
                    .distribusionAirportTransfersIFramePinnedBookingBarSettings
                    .fields.distribusionWidgetArrivalStation,
                arrivalArea:
                  this
                    .distribusionAirportTransfersIFramePinnedBookingBarSettings
                    .fields.distribusionWidgetArrivalArea,
                arrivalCity:
                  this
                    .distribusionAirportTransfersIFramePinnedBookingBarSettings
                    .fields.distribusionWidgetArrivalCity,
              },
            });
            resolve();
          } else if (attempts < maxAttempts) {
            attempts++;
            setTimeout(tryInitialize, 500);
          } else {
            console.log("Failed to load");
          }
        };

        tryInitialize();
      });
    },
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>
