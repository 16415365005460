<template>
  <div
    class="hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.image.fields.umbracoFile.src + ')',
    }"
  >
    <div class="inner">
      <div class="hero__content">
        <h1 class="hero__heading">{{ heroData.fields.title }}</h1>
        <p>{{ heroData.fields.subtitle }}</p>
        <div class="hero__content__small">
          <button
            class="btn btn--primary btn--hero"
            @click.prevent="formModal = true"
          >
            Search
          </button>
        </div>
        <div class="grid hero__content__large">
          <div class="grid__quarter">
            <label for="entryTime">Cruise Company</label>
            <Select2
              class="selectbox"
              :options="cruiseLinersFiltered"
              :settings="{
                placeholder: 'Click to select cruise line',
              }"
              v-model="chosenCruiseLiner"
            />
          </div>
          <div class="grid__quarter">
            <label for="entryTime">Cruise Ship</label>
            <Select2
              class="selectbox"
              :options="cruiseShips"
              :settings="{
                placeholder: 'Click to select ship',
              }"
              v-model="chosenCruiseShip"
            />
          </div>
          <div class="grid__quarter">
            <label for="entryDate">Date</label>
            <Select2
              class="selectbox"
              :options="cruiseDates"
              :settings="{
                placeholder: 'Select Date',
              }"
              v-model="chosenSailingIds"
            />
          </div>
          <div class="grid__quarter">
            <label for="promoCode">Promo Code</label>
            <input
              type="text"
              v-model="promoCode"
              :placeholder="this.labels.value.labelPromoCode"
            />
          </div>
          <div class="grid__full submit-wrapper">
            <form
              method="post"
              novalidate="novalidate"
              data-role="parking-form"
              v-on:submit="submitForm"
            >
              <input type="hidden" value="1" name="parkingDetailsSubmitted" />
              <input type="hidden" value="1" name="progressToNextStep" />
              <input type="hidden" name="promocodes" v-model="promoCode" />
              <input
                type="hidden"
                name="sailingIds"
                v-model="chosenSailingIds"
              />
              <input
                type="hidden"
                name="cruiseLinerId"
                v-model="chosenCruiseLiner"
              />
              <input
                type="hidden"
                name="cruiseShipId"
                v-model="chosenCruiseShip"
              />
              <input
                type="submit"
                class="btn btn--primary btn--hero"
                value="Search"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hero__gradient"></div>
    <!--small screen form in modal-->
    <div
      class="modal-content-wrapper modal-content-wrapper--form"
      v-show="formModal"
    >
      <div class="modal-content-background" @click.prevent="formModal = false">
        <div class="modal-content-background__close" title="Close"></div>
      </div>
      <div class="modal-content-inner">
        <div class="modal__content">
          <div class="grid">
            <div class="grid__full">
              <label for="entryTime">Cruise Company</label>
              <Select2
                class="selectbox"
                :options="cruiseLinersFiltered"
                :settings="{
                  placeholder: 'Click to select cruise line',
                }"
                v-model="chosenCruiseLiner"
              />
            </div>
            <div class="grid__full">
              <label for="entryTime">Cruise Ship</label>
              <Select2
                class="selectbox"
                :options="cruiseShips"
                :settings="{
                  placeholder: 'Click to select ship',
                }"
                v-model="chosenCruiseShip"
              />
            </div>
            <div class="grid__full">
              <label for="entryDate">Date</label>
              <Select2
                class="selectbox"
                :options="cruiseDates"
                :settings="{
                  placeholder: 'Select Date',
                }"
                v-model="chosenSailingIds"
              />
            </div>
            <div class="grid__full">
              <label for="promoCode">Promo Code</label>
              <input
                type="text"
                v-model="promoCode"
                :placeholder="this.labels.value.labelPromoCode"
              />
            </div>
            <div class="grid__full submit-wrapper">
              <form
                method="post"
                novalidate="novalidate"
                data-role="parking-form"
                v-on:submit="submitForm"
              >
                <input type="hidden" value="1" name="parkingDetailsSubmitted" />
                <input type="hidden" value="1" name="progressToNextStep" />
                <input type="hidden" name="promocodes" v-model="promoCode" />
                <input
                  type="hidden"
                  name="sailingIds"
                  v-model="chosenSailingIds"
                />
                <input
                  type="hidden"
                  name="cruiseLinerId"
                  v-model="chosenCruiseLiner"
                />
                <input
                  type="hidden"
                  name="cruiseShipId"
                  v-model="chosenCruiseShip"
                />
                <input
                  type="submit"
                  class="btn btn--primary btn--hero"
                  value="Search"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- This can go anywhere in the component as needed. Closing the modal needs to reset this.chosenCruiseShip to '' -->
    <div
      class="modal-content-wrapper modal-content-wrapper--no-ship"
      v-show="chosenCruiseShip == -1"
    >
      <div
        class="modal-content-background"
        @click.prevent="chosenCruiseShip = ''"
      >
        <div class="modal-content-background__close" title="Close"></div>
      </div>
      <div
        class="modal-content-inner"
        v-html="heroData.fields.notFoundContent"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";

export default {
  name: "HeroCruise",
  props: ["heroData"],
  inject: ["publicSiteSettings", "labels"],
  components: {
    Select2,
  },
  methods: {
    submitForm(e) {
      if (!this.chosenSailingIds) {
        alert("You must select a sailing date to continue");
        e.preventDefault();
      } else {
        e.target.action =
          this.publicSiteSettings.value.fields.aeroParkerParkingUrl;
      }
    },
  },
  data() {
    return {
      promoCode: this.heroData.fields.internalPromoCode ?? "",
      apiData: [],
      chosenCruiseLiner: "",
      chosenCruiseShip: "",
      chosenCruiseDate: "",
      chosenSailingIds: "",
      formModal: false,
    };
  },
  computed: {
    cruiseModalVisible: function () {
      return this.chosenCruiseLiner == -1;
    },
    cruiseLiners: function () {
      return this.apiData.cruiseLiners
        ? this.apiData.cruiseLiners.map(function (item) {
            return { id: item.id, text: item.name };
          })
        : [];
    },
    cruiseLinersFiltered: function () {
      const apiData = this.apiData;
      if (apiData && apiData.cruiseLiners) {
        const validShips = apiData.cruiseShips.filter(function (ship) {
          return apiData.datepicker.some(function (date) {
            return date["cruise-ships"].some(function (nestedShip) {
              return ship.id == nestedShip.id;
            });
          });
        });

        const validLiners = apiData.cruiseLiners
          .filter(function (liner) {
            return validShips.some(function (ship) {
              return ship.cruiseLinerId == liner.id;
            });
          })
          .map(function (item) {
            return { id: item.id, text: item.name };
          });

        const sortedLiners = validLiners.sort(function (a, b) {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });

        return sortedLiners;
      } else {
        return [];
      }
    },
    cruiseShips: function () {
      const chosenCruiseLiner = this.chosenCruiseLiner;
      const apiData = this.apiData;
      const validShips = chosenCruiseLiner
        ? apiData.cruiseShips
            .filter(function (ship) {
              return (
                ship.cruiseLinerId == chosenCruiseLiner &&
                apiData.datepicker.some(function (date) {
                  return date["cruise-ships"].some(function (nestedShip) {
                    return ship.id == nestedShip.id;
                  });
                })
              );
            })
            .map(function (item) {
              return { id: item.id, text: item.name };
            })
        : [];

      const sortedShips = validShips.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      sortedShips.push({ id: -1, text: "My cruise is not listed" });
      return sortedShips;
    },
    cruiseDates: function () {
      const chosenCruiseShip = this.chosenCruiseShip;
      return chosenCruiseShip && chosenCruiseShip > -1
        ? this.apiData.datepicker
            .filter(function (date) {
              return date["cruise-ships"].some(function (ship) {
                return ship.id == chosenCruiseShip;
              });
            })
            .map(function (item) {
              const currentCruise = item["cruise-ships"].filter(function (
                ship
              ) {
                return ship.id == chosenCruiseShip;
              })[0];

              // build display date
              const displayDate = [];
              item.date.split("-").forEach((datePart) => {
                if (datePart.length < 2) {
                  displayDate.push(`0${datePart}`);
                } else {
                  displayDate.push(datePart);
                }
              });

              return {
                id: item["sailing-ids"],
                text:
                  displayDate.join("-") +
                  " (" +
                  currentCruise.nights +
                  " nights)",
              };
            })
        : [];
    },
  },
  mounted() {
    axios
      .get(
        this.publicSiteSettings.value.fields.aeroParkerParkingUrl.split(
          "Shop/"
        )[0] + "Shop/CruiseInformation/ABP"
      )
      .then((response) => {
        this.apiData = response.data;
      });
  },
};
</script>

<style lang="scss">
.hero {
  &__heading {
    @include breakpoint($tablet) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: none;
    text-align: center;

    @include breakpoint($desktop-sml) {
      &__small {
        display: none;
      }
    }

    &__large {
      display: none;

      @include breakpoint($desktop-sml) {
        display: unset;
      }
    }

    label {
      color: $white;
      margin-top: 1rem;
    }

    @include breakpoint($desktop) {
      max-width: none;
    }

    .selectbox,
    label {
      text-align: left;
    }
  }

  p {
    font-size: 1.9rem;
    margin-top: -1.5rem;
    font-family: $font-headings;
    margin-bottom: 3rem;
    color: $white;

    @include breakpoint($tablet) {
      margin-left: auto;
      margin-right: auto;
      max-width: 75%;
    }

    @include breakpoint($desktop-sml) {
      font-size: 3rem;
      line-height: 1.2;
      max-width: none;
    }
  }
}

.submit-wrapper {
  padding-top: 3rem;
}

.modal-content-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  @include breakpoint($desktop-sml) {
    &--form {
      display: none;
    }
  }
}

.modal-content-background {
  background-image: linear-gradient(
    45deg,
    rgba(#113274, 0.6),
    rgba(#a4b7ce, 0.6)
  );
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &__close {
    background-color: $skin-colour-primary-bright;
    border-radius: 50%;
    content: "";
    cursor: pointer;
    height: 3rem;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 3rem;
    z-index: 1;

    @include breakpoint($tablet) {
      right: 1rem;
      top: 1rem;
    }

    @include breakpoint($desktop) {
      right: calc(50% - (350px + 1.5rem));
    }

    &::before,
    &::after {
      background: #fff;
      content: "";
      height: 2rem;
      left: calc(1.5rem - 0.1rem);
      position: absolute;
      top: calc(1.5rem - 1rem);
      transform: rotate(45deg);
      width: 0.2rem;
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.modal-content-inner {
  left: 50%;
  max-height: calc(100% - 2rem);
  max-width: 700px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 2rem);

  .modal-content-wrapper--form & {
    max-width: 450px;

    label {
      color: #333;
      margin-top: 1rem;
    }
  }
}

.modal__content {
  background-color: #fff;
  color: #333;
  padding: 1em;
  text-align: center;

  .modal-content-wrapper--form & {
    text-align: left;
  }

  .modal-content-wrapper--no-ship & .btn {
    @extend .btn--primary;
    margin: 0 0.5rem 1rem;
    max-width: 280px;
    width: 100%;

    &--outline {
      background: none;
      box-shadow: inset 0 0 0 1px $skin-colour-primary-bright;
      color: $skin-colour-primary-bright;
    }
  }
}
</style>
