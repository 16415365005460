<template>
  <section v-if="filteredComponents != null" class="menu-section">
    <div class="menu-bar">
      <div
        v-for="component in filteredComponents"
        :key="component.system.id"
        class="menu-item"
        :data-target="'anchor-' + addHyphensIfSpaces(component.fields.title)"
      >
        {{ component.fields.title }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AnchorMenuBar",
  props: ["components"],
  data() {
    return {
      filteredComponents:
        this.components.filter(
          (component) => component.fields.displayInAnchorMenuComponent === true
        ).length > 0
          ? this.components.filter(
              (component) =>
                component.fields.displayInAnchorMenuComponent === true
            )
          : null,
    };
  },
  methods: {
    addHyphensIfSpaces(str) {
      if (str.includes(" ")) {
        return str.split(" ").join("-");
      }
      return str;
    },
    initializeMenuListeners() {
      const menuItems = document.querySelectorAll(".menu-item");

      menuItems.forEach((item) => {
        item.addEventListener("click", () => {
          menuItems.forEach((i) => i.classList.remove("active"));

          item.classList.add("active");

          const targetId = item.getAttribute("data-target");
          const targetSection = document.getElementById(targetId);

          if (targetSection) {
            targetSection.scrollIntoView({ behavior: "smooth" });
          }
        });
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeMenuListeners();
    });
  },
};
</script>

<style lang="scss" scoped>
.menu-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  height: 8rem;
}

.menu-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border: 1px solid #ddd;
}

.menu-item {
  padding: 15px 25px;
  text-align: center;
  cursor: pointer;
  color: #555;
  border: 1px solid transparent;
  border: 1px solid #ddd;
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.menu-item.active {
  background-color: #ccc;
  color: white;
  border-color: #ccc;
}

@media screen and (max-width: 768px) {
  .menu-section {
    height: 26rem;
  }

  .menu-bar {
    width: 90%;
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }

  .menu-item {
    width: 100%;
    text-align: left;
    padding: 15px;
    text-align: center;
  }
}
</style>
