<template>
  <section>
    <div class="inner">
      <h4
        class="heading"
        v-if="
          travelInsurancePinnedBookingBarSettings.fields.travelInsuranceHeading
        "
      >
        {{
          travelInsurancePinnedBookingBarSettings.fields.travelInsuranceHeading
        }}
      </h4>
      <div
        v-if="
          travelInsurancePinnedBookingBarSettings.fields
            .travelInsuranceContentField
        "
        class="rich-text-block"
        v-html="
          travelInsurancePinnedBookingBarSettings.fields
            .travelInsuranceContentField
        "
      ></div>
      <a
        class="btn btn--primary"
        v-if="
          travelInsurancePinnedBookingBarSettings.fields.travelInsuranceLink
            ?.url
        "
        :href="
          travelInsurancePinnedBookingBarSettings.fields.travelInsuranceLink
            ?.url
        "
        :target="
          travelInsurancePinnedBookingBarSettings.fields.travelInsuranceLink
            ?.target
        "
      >
        {{
          travelInsurancePinnedBookingBarSettings.fields.travelInsuranceLink
            ?.name
        }}
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: "StickyBookingBarTravelInsurance",
  props: {
    travelInsurancePinnedBookingBarSettings: Object,
    selectedLanguage: String,
  },
  methods: {
    hideKeyboardOnMobile() {
      if (document.body.classList.contains("has-overlay")) {
        return "readonly";
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.heading {
  font-size: 2.2rem;
  color: #1f2654;

  @media screen and (min-width: $desktop-sml) {
    color: #ffffff;
  }
}

.rich-text-block {
  color: #1f2654 !important;

  @media screen and (min-width: $desktop-sml) {
    color: #ffffff !important;
  }
}
</style>
