<template>
  <div class="inner">
    <div class="hero__content">
      <!-- commented out the Airport Services button for now as it might be needed at a later date -->
      <!-- <ul class="breadcrumb">
        <li>
          <a href="#" tabindex="0"><span>Airport services</span></a>
          <meta itemprop="position" content="1" />
        </li>
      </ul> -->
      <h1 class="hero__heading">{{ heroData.fields.securityHeroHeading }}</h1>
      <div class="hero-form hero-form--booking">
        <div class="grid">
          <div class="grid__half">
            <div class="grid">
              <div class="grid__half">
                <label for="entryDate">{{
                  this.labels.value.labelEntryDate
                }}</label>
                <DatePicker
                  v-model="entryDate"
                  :min-date="new Date()"
                  :masks="{ input: this.displayFormat }"
                  class="datepicker"
                  :popover="{ visibility: 'focus' }"
                  is-required
                  :v-model="arrivalDate"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      :value="inputValue || this.labels.value.labelDate"
                      v-on="inputEvents"
                    />
                  </template>
                </DatePicker>
              </div>
              <div class="grid__half">
                <label for="entryTime">{{ this.labels.value.labelTime }}</label>
                <Select2
                  name="AirportVisitDetails_arrival_hour"
                  data-field="hidden-arrival-hour"
                  class="selectbox-time"
                  :options="optionsTime"
                  :settings="{
                    placeholder: this.labels.value.labelTime,
                  }"
                  v-model="entryTime"
                />
              </div>
            </div>
          </div>
          <div class="grid__half">
            <div class="grid">
              <div class="grid__half">
                <label for="loungeAdults">
                  {{ this.labels.value.labelAdults }}
                </label>
                <Select2
                  name="Lounge_numAdults"
                  data-field="hidden-adult"
                  class="selectbox"
                  :options="['1', '2', '3', '4', '5', '6']"
                  :settings="{
                    placeholder: '0',
                  }"
                  v-model="loungeAdults"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="grid">
          <form
            @submit="submitSecurityForm"
            :action="reactivePublicSiteSettings.fields.pBBSecurityFormLik"
            method="post"
            target="_blank"
            class="ng-pristine ng-valid"
          >
            <div class="grid__full">
              <button
                v-if="!heroData.fields.securityHideButton"
                type="submit"
                class="btn btn--primary btn--hero"
                tabindex="0"
              >
                {{ this.labels.value.buttonBook }}
              </button>
            </div>
            <!-- Primary fields for the Security banner -->
            <input type="hidden" value="1" name="submitted" />
            <input
              type="hidden"
              name="arrivalDate"
              v-model="entryDateNormalised"
            />
            <input
              type="hidden"
              name="AirportVisitDetails_arrival_hour"
              v-model="entryTime"
            />
            <input
              type="hidden"
              name="SimpleTicket_numTickets"
              v-model="loungeAdults"
            />
            <input
              type="hidden"
              name="EscapeLounge_numAdults"
              v-model="loungeAdults"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import { DatePicker } from "v-calendar";
import parkingForm from "../../mixins/parkingForm";
import dayjs from "dayjs";

const LOCALE_CONFIG_MAPPING = {
  "en-US": {
    currency: "$",
    inputFormat: "MM/dd/yyyy",
    displayFormat: "MM/DD/YYYY",
  },
  "en-EU": {
    currency: "€",
    inputFormat: "dd/MM/yyyy",
    displayFormat: "DD/MM/YYYY",
  },
  "en-GB": {
    currency: "£",
    inputFormat: "dd/MM/yyyy",
    displayFormat: "DD/MM/YYYY",
  },
  // add more mappings as needed
};

export default {
  name: "SecurityForm",
  props: ["heroData"],
  mixins: [parkingForm],
  computed: {
    optionsTime() {
      return this.buildSecurityTimeSlots();
    },
    localeConfig() {
      // Return a default configuration if locale not found.
      return (
        LOCALE_CONFIG_MAPPING[
          this.publicSiteSettings.value.fields.globalLocale
        ] || LOCALE_CONFIG_MAPPING["en-US"]
      );
    },
    inputFormat() {
      return this.localeConfig.inputFormat;
    },
    displayFormat() {
      return this.localeConfig.displayFormat;
    },
    entryDateAndTime() {
      const [hours, minutes] = this.entryTime.split(":");
      return new Date(
        this.entryDate.getFullYear(),
        this.entryDate.getMonth(),
        this.entryDate.getDate(),
        hours,
        minutes
      );
    },
    entryDateNormalised: function () {
      return dayjs(this.entryDate).format(this.displayFormat);
    },
  },
  data() {
    return {
      arrivalDate: new Date(),
      entryDate: "",
      entryTime: "",
      loungeAdults: "1",
    };
  },
  methods: {
    submitSecurityForm(e) {
      e.target.submit();
      var minimumNotice =
        parseInt(
          this.publicSiteSettings.value.fields.aeroParkerParkingMinimumNotice
        ) || 0;
      var minimumTime = this.publicSiteSettings.value.fields.timezone
        ? new Date(this.publicSiteSettings.value.fields.timezone.currentTime)
        : new Date();
      minimumTime.setMinutes(minimumTime.getMinutes() + minimumNotice);
      if (
        this.entryDateAndTime < minimumTime ||
        this.exitDateAndTime < minimumTime
      ) {
        var timeValidationMessage = "";
        if (minimumNotice === 0) {
          timeValidationMessage = this.labels.value.validationTimeGeneric;
        } else if (minimumNotice % 60 === 0) {
          timeValidationMessage = this.labels.value.validationTimeHours.replace(
            "{0}",
            minimumNotice / 60
          );
        } else {
          timeValidationMessage =
            this.labels.value.validationTimeMinutes.replace(
              "{0}",
              minimumNotice
            );
        }
        alert(timeValidationMessage);
        e.preventDefault();
      } else if (this.exitDateAndTime <= this.entryDateAndTime) {
        alert(this.labels.value.validationTimeOrder);
        e.preventDefault();
      }
    },
  },
  components: {
    Select2,
    DatePicker,
  },
};
</script>
